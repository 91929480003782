.invalid-field,
.invalid-field:focus {
  border-color: rgba(146, 0, 0, 0.5) !important;
  outline: 0 none;
  box-shadow: none;
}

.invalid-field-warning {
  font-size: 12px;
  color: rgba(146, 0, 0, 0.9) !important;
  display: block;
  white-space: nowrap;
}

.invalid-field-warning::before {
  content: '* ';
}
